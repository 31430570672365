export const schools = [
  "TK JNY",
  "TK RA. Nurul Falah",
  "TKK Penabur",
  "TK Mitra Penabur",
  "TK Kartika",
  "TK Kepik",
  "TK Cita Buana",
  "TK Nurul Huda",
  "TK Cikal",
  "TK Strada Bhakti Utama",
  "TK BHK Cibubur",
  "TK Kemala Bhayangkari PTIK",
  "TK Al Barkah Kelapa Gading",
  "TK Pangudi Luhur",
  "TKK 9 Penabur",
  "TK RA Istiqlal",
  "TK Darussalam",
  "TK Paud Aster Wisma Tajur",
  "TK HighScope",
  "TK Efata",
  "TK Nurul Jihad",
  "TK Poris Indah",
  "TK Playgroup Montessori",
  "TK ABC Kids",
  "TK Growing Mine",
  "TK Kinderland",
  "SD Al-Azhar Syifa Budi Cibinong",
  "Syafana Islamic School",
  "Tangerang",
  "Bunda Mulia School",
  "Mentari International School",
  "Lazuardi GIS",
  "Raffles Christian School",
  "SD Permata Harapan",
  "SD Springfield International School",
  "Jakarta Islamic School",
  "SD Ichthus",
  "SD Bina Nusa Bangsa",
  "SD Madania",
  "SDN Menteng 01",
  "SD Lentera Kasih Sunter",
  "Sekolah Bina Tunas Cemerlang",
  "SD Nabawi Islamic School",
  "SDK Penabur Gading Serpong",
  "SD Bhakti Mulya 400",
  "SD Bunda Hati Kudus",
  "SD St Angelina",
  "SD IPEKA",
  "SD Yasporbi",
  "SD Putik Cipayung",
  "Sekolah Bina Gita Gemilang",
  "SD Kristen Anglo",
  "SD Cikal Cilandak",
  "SD Mentari",
  "SDIT Al Haraki",
  "SD Al – Jannah",
  "SD Al Iklhas",
  "SD Al Zahra",
  "SDN 5 Jati",
  "SDN Rancabungur 1",
  "SD Jati Ranggon Bekasi",
  "Sekolah Perkumpulan Mandiri",
  "SD Tarakanita 1 Barito Jaksel",
  "SD Advent XV",
  "SD St Theresia",
  "SDIT Raudhatul Muttaqien",
  "SD Strada TKM 2",
  "Morning Star Academy",
  "SD HighScope",
  "SD Madina Tebet",
  "SD Tarakanita 5",
  "SD Permata Bunda Cisalak",
  "SD Kemala Bhayangkari 3",
  "SD Pangudi Luhur",
  "SD Al Azhar Syifa Budi Kemang",
  "SD Dharmais Karta UT",
  "SD St Ursula",
  "SD 12",
  "SDI Al ‘Ashar",
  "SDN 06 Petukangan",
  "SD Abdi Siswa Aries",
  "SDN 01",
  "SD Binus Simprug",
  "Al-Bayan Islamic School",
  "SD Wijaya Kusuma",
  "SD St Vincentius",
  "SDS Hang Tuah",
  "SD IT Fajar Hidayah",
  "SD BPK Penabur Bintaro",
  "MI Daarul Maarif",
  "SDIT As Saadah",
  "SD Putra 1",
  "Tambun Islamic School",
  "SD Perguruan Rakyat IV Pondok Bambu",
  "SD Cibubur",
  "Sekolah Hillasris",
  "SD Al-Azhar 01",
  "SD Pelita II",
  "SD Al-Chasanah",
  "SD Ricci II",
  "SDIT Nurhikmah",
  "SD Tarakanita 2",
  "SD Strada Bhakti Utama",
  "SDIT Amani Ar Rasyid",
  "Sekolah Alam Bintaro",
  "SDN 01 Gandul Pagi",
  "SDIT Baiturrahman",
  "SDN 01 Jaktim",
  "SDN Cipete 01",
  "SD Kinderfield",
  "Binus School",
  "SD Santo Ignatius",
  "Azhari Islamic School",
  "SD 05 Sepanjangjaya",
  "SDN Jelambar 01",
  "SD Universal Kelapa Gading",
  "SD Al Azhar Syifa Budi Legenda",
  "SD Mardi Waluya",
  "SD Al Azhar Syifa Budi Jakarta",
  "SDIT Alqolam",
  "MI Istiqlal",
  "SDIT Nurul Fikri",
  "SD Muhammadiyah Pamulang",
  "SDI Al Azhar 5",
  "SD Citra Bangsa",
  "SD Al Azhar 23",
  "SD Kreativitas Anak Indonesia",
  "SDN Bangka 3",
  "Sekolah Kristen Ketapang 3",
  "SD Melania 3",
  "MI Terpadu Al-Hamid",
  "SDN 06 Petang Kelapa Gading",
  "SD Al-Azhar 46",
  "SD Cita Buana",
  "SDSN Cipinang 01 Pagi",
  "SDN Ciputat VI",
  "SDN Meruya Utara 10 Pagi",
  "SD Santo Lucas",
  "SD Al Azhar 8",
  "SDIT Muhajirin",
  "SD Tunas Bangsa Citra",
  "SD Al-Azhar Bintaro",
  "SD Al Azhar 20 Cibubur",
  "SD Pamardi Yuwana Bhakti",
  "SD Nurul Fajar",
  "SDN 09 Ragunan",
  "SDIT Al Mu’min",
  "SDN Pondok Labu 11",
  "SD Maha Prajna",
  "SD Angkasa 9",
  "SD Granada",
  "SDIT Harum",
  "SD Azizah",
  "SDK Penabur – Kota Wisata Cibubur",
  "An Najm Islamic School",
  "Gandhi School Ancol",
  "Mentari School",
  "SMPK 4 Penabur Kelapa Gading",
  "SMP Islam Tugasku Pulomas Jaktim",
  "BPK Penabur Cipinang",
  "SMP Islam Al Azhar",
  "SMP Labschool Kebayoran",
  "SMPN 24 Tangerang",
  "SMP Citra Kasih Jakarta",
  "SMPK 5 Penabur Cipinang",
  "SMP Al Azhar Kemandora",
  "SMP Al Azhar 10",
  "SMPN 73",
  "SMPN 15 Jakarta",
  "SMPN 15 Bekasi Selatan",
  "SMPN 255",
  "SMP BHK Kota Wisata",
  "SMPK Abdi Siswa Arie Jakarta Barat",
  "Jubilee School Kemayoran",
  "SMP Islam Al Fikri",
  "SMPN 1 Cikini",
  "SMPN 157",
  "SMP Islam Al Azzahra 22 Sentra Primer",
  "SMP Tarakanita 4",
  "SMP Mater Dei Pamulang",
  "SMP Pangudi Luhur Jaksel",
  "SMP Al-Ikhlas",
  "SMP Abdi Siswa",
  "SMP Mardi Waluya Cibinong",
  "SMP HighScope",
  "SMP Pamardiyuana Bakti",
  "SMPN 109",
  "SMP Darussalam",
  "SMP Santa Ursula Jakarta",
  "SMP Paramarta",
  "SMPIT Nururrahman",
  "SMP Sang Timur",
  "SMP N 41",
  "SMP Al Izhar 25 Tangsel",
  "SMPN 91",
  "IPEKA Sunter",
  "SMP Bakti Mulya 400",
  "SMPN 7 Depok",
  "SMP Andreas",
  "SMPN 75",
  "SMPN 21 Tangsel",
  "SMP KK Marsudirini",
  "SMPN 2",
  "SMPN 8 Tangsel",
  "SMP 205",
  "SMP Labschool",
  "SMP 47",
  "SMP Al Azhar BSD",
  "SMPIT Raudhatul Muttaqin",
  "SMPN 5 Tangsel",
  "SMP Abdi Siswa",
  "SMPN 177",
  "SIS Kelapa Gading",
  "SMP Sophos",
  "SMPN 85",
  "SMPN 99 Jakarta Timur",
  "SMP Kolese Kanisius Menteng",
  "SMPN 2 Depok",
  "SMP Tunas Bangsa",
  "SMP Mahatma Gandhi",
  "SMP Insan Rabani",
  "SMP Della Strada",
  "SMPN 11 kota Tangerang",
  "Insan Cendikia Boarding Sentul",
  "SMP Don Bosco",
  "SMP Semplak 1",
  "SMPN 1 Bogor",
  "SOPHOS School Indonesia",
  "SMP Al Azhar Syifa Budi",
  "SMP Santo Markus",
  "SMP Ar Rasyid",
  "SMPN 11 Jakarta",
  "SMP Al Azhar Rawamangun",
  "SMP Calvin",
  "SMP Bangun Nusa Bangsa",
  "Binus School Serpong",
  "SMP Nizamia Andalusia",
  "SMP PSKD Mandiri",
  "SMP Athalia",
  "SMPIT Nurhikmah",
  "SMPK Ketapang",
  "SMP 2 Kota Bekasi",
  "Pesantren Al Hamadiyah",
  "Homeschooling Persada",
  "Ponpes Modern Darus-Sholihin",
  "SMPN 245",
  "ACS Jakarta Secondary School",
  "Global Prestasi School",
  "Global Jaya School",
  "Global Sevilla Pulomas",
  "SMP Madania",
  "Raffles Christian School",
  "SMP Cikal",
  "SMP 107",
  "SMP Negeri 115",
  "Singapore International School",
  "SMP Pribadi",
  "SMP Kharisma Bangsa",
  "SMA Global Mandiri Cibubur",
  "British International School",
  "Saint Johns School Meruya",
  "SMA Pelita Harapan Sentul City",
  "SMA Cikal Amri",
  "Gandhi Memorial Internasional School",
  "SMA Kharima Bangsa",
  "Mentari Intercultural School Jakarta",
  "Jakarta International School",
  "Melrose High school",
  "SMAN 81 Jakarta",
  "Sampoerna Academy",
  "SMA Global Islamic School",
  "SMKN 1 Cibinong",
  "Sekolah Bogor Raya",
  "SMA Springfield",
  "PSKD Mandiri",
  "SMAI Al-Azhar 3",
  "High Scope TB Simatupang",
  "SMA 101",
  "SMAN 39 Jakarta",
  "SMA Kolese Gonzaga",
  "Victory Plus International School",
  "SMA 78",
  "SMA Tirtamarta Penabur",
  "SMA Plus Dharma Karya",
  "SMA Tarsisius",
  "SMAN 28",
  "SMA St Theresia",
  "SMAN 17 Bekasi",
  "SMAK 7 Penabur",
  "SMA Raffless",
  "SMA 50",
  "SMA Al-Azhar BSD",
  "SMP 711 Tangsel",
  "SMK N 57",
  "SMA Penabur",
  "SMA 99",
  "SMAN 29 JKT",
  "SMA BPK Penabur Gading Serpong",
  "SMKN 40 Jakarta",
  "SMA Mahatma Gandhi",
  "SMAN 70 Bulungan",
  "SMA Bina Insan",
  "SMA Penabur Bintaro",
  "SMA 45 Jakarta",
  "SMA Nasional 1",
  "SMA 90",
  "SMAN 35 Jakarta",
  "SMA Darma Putra",
  "SMA Islam Darussalam",
  "SMA Bakti Mulya 400",
  "Bogor Central School",
  "SMA Santa Ursula",
  "SMAN 4 Cibinong",
  "SMA Bunda Mulya",
  "SMA Muhammadiyah 12",
  "SMAN 4 Depok",
  "SMAN 70 JAKARTA",
  "SMA Permata Indah",
  "SMA Al Izhar",
  "SMAN 3 Jakarta",
  "SMA Tarakanita 1",
  "SMAN 10 Bogor",
  "SMA PKP JIS",
  "SMAN 1 Depok",
  "SMA Al-Azhar",
  "Jubilee School Kemayoran (SMA)",
  "SMA IT Al Madinah",
  "SMAN 82 Jaksel",
  "SMA Kolese Kanisius",
  "SMA Al Mubarak",
  "SMA Dian Didaktika",
  "SMAN 1 Jakarta",
  "SMA IT Nurul Fikri BS",
  "SMAN 7 Jakarta",
  "SMA Yuppentek 1 Tangerang",
  "SMAN 85",
  "SMA 38",
  "SMAN 112",
  "Anak Panah Cyberschool",
  "SMAK 1 Penabur",
  "SMAN 2 Depok",
  "SMA Al-Falah",
  "SMA N 62 Jakarta",
  "SMA N 97 Jakarta",
  "SMA N 99 Jakarta",
  "SMA Regina Pacis",
  "SMA K 7 Penabur",
  "SMAN 44",
  "British School Jakarta",
  "SMAN 2 Tangsel",
  "Madrasah Pembangunan UIN Jakarta",
  "SMAN 65",
  "SMAN 9 Jakarta",
  "SMA Marie Joseph",
  "SMA Amaliah",
  "SMAN 34 Jaksel",
  "Homeschooling Kak Seto",
  "Ponpes Al Mukhlisin",
  "SMAN 8",
  "SMAN 55",
  "SMA Vianney",
  "SMAN 36 Jakarta Timur",
  "SMA Penabur Secondary Tanjung Duren",
  "SMAN 2 Kota Bekasi",
  "SMAN 8 Tangerang",
  "SMA Al-Azhar Kemang",
  "SMK Analis Kesehatan Ditkesad",
  "SMA Avicenna Jagakarsa",
  "SMA 25 Jakarta",
  "SMAN 3 Depok",
  "SMA Al Azhar 8",
  "SMAIT Nurrahma",
  "SMAN 60 Jakarta",
  "SMAT Krida Nusantara Bandung",
  "SMAN 73 Jakarta",
  "SMAN 42 Jakarta",
  "SMA Mardi Yuana",
  "SMAN 53",
  "SMK Harapan Bangsa",
  "Pesantren Darunnajah",
  "SMAN 66 Jakarta",
  "SMAN 46 JAKARTA",
  "International Islamic Boarding School",
  "SMAN 21",
  "MA Darunnajah Ulujami",
  "SMA 4 TGR",
  "SMA Yadika 6",
  "SMU 34 jaksel",
  "SMA Bunda Hati Kudus Cibubur",
  "SMAN 72",
  "Sekolah Harapan Bangsa",
  "SMAN 11",
  "SMA 100",
  "SMAN 68 Jakarta",
  "SMAN 2 Tambun",
  "SMAN 48 Jakarta",
  "SMA Assidiqiyyah Batu Ceper",
  "SMF Puskesad",
  "SMAN 7 Depok",
  "SMA Santo Mikael",
  "SMAN 6 Jakarta",
  "SMA Mahanaim",
  "SMK 27",
  "SMAN 11 Jakarta",
  "SMA Cita Buana",
  "SMA Garuda Cendekia",
  "SMAN 42",
  "SMAN 2 Tangsel",
  "SMA Don Bosco",
  "SMA N 1 Depok",
  "SMA Plus Pembangunan Jaya",
  "SMAN 39 Cijantung",
  "SMA Pusaka 1",
  "SMA 6 Tangsel",
  "Man 12 Jakarta",
  "SMAN 29 Jakarta",
  "SMAN 84 Jakarta",
  "SMU 7 BPK Penabur",
  "SMA Quran Al Ihsan",
  "SMA Kharisma Bangsa",
  "SMA Fons Vitae 1",
  "MAN 13 Jakarta",
  "SMA Presiden",
  "SMU 71",
  "SMAN 66",
  "SMAK 3 penabur",
  "SMA Yokobus",
  "SMAN 38",
  "SMK Mondial",
  "SMA Al Azhar 19",
  "SMA Ananda Bekasi",
  "SMAN 1 Cibinong",
  "SMA Al Izhar Pondok Labu",
  "SMAN 3",
  "SMA N 11 Depok",
  "SMAK Ricci II",
  "SMAN 5 Bekasi",
  "SMA N 58",
  "SMAN 7 Bekasi",
  "SMAN 26",
  "MAN 1 Jakarta",
  "SMA 9",
  "SMA N 3 Tangerang",
  "SMAN 49 Jakarta",
  "SMA Dwi Warna",
  "SMA 13 Bekasi",
  "SMAN 77",
  "IPEKA PLUS BSD",
  "PKBM 32",
];
