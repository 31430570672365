import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import AsalSekolahSiswaEdumatrix from "../../../Components/AsalSekolahSiswa/AsalSekolahSiswa";
import PromoFree from "../../../Components/BannerPromo/PromoFree";
import AccordionFAQ from "../../../Components/FAQ/AccordionFAQ";
import FiturProgram from "../../../Components/FiturProgram/FiturProgram";
import Keunggulan from "../../../Components/Keunggulan/Keunggulan";
import MasterCarousel from "../../../Components/MasterCarousel/MasterCarousel";
import MatrixFeatures from "../../../Components/MatrixFeatures/MatrixFeatures";
import OnlineLesOptions from "../../../Components/OnlineLesOption/OnlineLesOption";
import PengajarLestPrivate from "../../../Components/PengajarLestPrivate/PengajarLestPrivate";
import ProgramBelajarLesPrivat from "../../../Components/ProgramBelajarLesPrivate/ProgramBelajarLesPrivate";
import ProgramLesPrivat from "../../../Components/ProgramLesPrivate/ProgramLesPrivate";
import PilihanProgram from "../../../Components/ProgramOptions/PilihanProgram";
import AsalSekolahSiswaMarque from "../../../Components/SekolahSiswaMarque/SekolahSiswaMarque";
import EmbrelaSlider from "../../../Components/SlideTop/EmbrelaSlider";
import { getAllKecamatanBySlug } from "../../../helper/request/getAllKecamatanBySlug";
import PromoHomepage from "../../../Home/PromoHomepage/PromoHomepage";
import HeroLesPrivate from "../../components/HeroLesPrrivate/HeroLesPrivate";
import ListKelurahanLesPrivate from "../../components/ListKelurahanLesPrivate/ListKelurahanLesPrivate";
import TableOfContents from "../../components/TableOfContent";
import LesPrivateBahasaHeader from "../components/LesPrivateBahasaHeader";
import { getHeroData } from "../../../utils/getHeroData";

const items = [
  {
    text: "Les Privat Bahasa – Guru Privat Bahasa Asing dan BIPA",
    href: "#les-privat-bahasa",
    subItems: [
      {
        text: "Program Les Privat Bahasa Asing dan BIPA",
        href: "#program-les-privat-bahasa",
      },
      {
        text: "Les Privat Bahasa Inggris Conversation dan Les Privat Bahasa Asing Offline dan Online",
        href: "#les-privat-bahasa-inggris",
      },
      {
        text: "Les Privat Bahasa Inggris Conversation dan Les Privat Bahasa Asing Offline dan Online",
        href: "#les-privat-bahasa-inggris",
      },
      {
        text: "Les Privat Bahasa Asing setiap hari dimana saja",
        href: "#les-privat-bahasa-setiap-hari",
      },
      {
        text: "Program Les Privat Bahasa Inggris Coversation dan Bahasa Asing",
        href: "#list-lest-private-bahasa",
      },
    ],
  },
  {
    text: "Pengajar Les Private Bahasa Asing",
    href: "#pengajar-les-private",
  },
  {
    text: "Fitur Program Les Private Bahasa Asing",
    href: "#fitur-program-les-private",
  },
  {
    text: "Pilihan Program Les Private Online/Offline Bahasa Asing",
    href: "#pilihan-program-les-private",
  },
  {
    text: "Program Les Private Matrix  ",
    href: "#program-les-private-edumatrix",
  },
  {
    text: "Saat Ini, Matrix Juga Melayani Les Privat Online",
    href: "#matrix-juga-melayani-les-privat-online",
  },
  {
    text: "Asal Sekolah Siswa Edumatrix les Private",
    href: "#asal-sekolah-siswa-edumatrix-private",
  },
];

const LesPrivateBahasaKecamatan = () => {
  const [kecamatan, setKecamatan] = useState([]);
  const [heroData, setHeroData] = useState(null);

  const { kecamatanSlug } = useParams();

  const nameCountry = kecamatan.kecamatan;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllKecamatanBySlug(kecamatanSlug);
        setKecamatan(response.data);

        const getHero = await getHeroData("BAHASA", "kecamatan");
        setHeroData(getHero);
      } catch (error) {
        console.error("Failed to fetch kecamatan data:", error);
      }
    };

    fetchData();
  }, [kecamatanSlug]);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`Bimbel Les Privat Bahasa Asing • Kursus Bahasa Inggris dan Asing di ${nameCountry} - Les
  Privat Matrix`}
        </title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={`Temukan les privat bahasa asing terbaik dengan berbagai pilihan program fleksibel, termasuk les privat online dan offline. Dukungan belajar terbaik untuk Bahasa Inggris dan bahasa asing lainnya di ${nameCountry}  bersama Les Private Matrix.
    `}
        />
        <meta
          name="keywords"
          content="
Les Privat Bahasa Asing,
Les Bahasa Inggris,
Les Privat Online Bahasa,
Les Privat Offline Bahasa,
Program Les Bahasa Asing,
Tutor Bahasa Asing,
Les Bahasa Jepang,
Les Bahasa Mandarin,
Les Bahasa Prancis,
Les Bahasa Spanyol,
Les Bahasa Jerman,
Les Bahasa Korea,
Les Bahasa Arab
"
        />
        <link
          rel="canonical"
          href="https://apps.bimbelmatrix.com/les-privat-bahasa-asing"
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={`Bimbel Les Privat Bahasa Asing • Kursus Bahasa Inggris dan Asing di ${nameCountry} - Les
  Privat Matrix`}
        />
        <meta
          property="og:description"
          content={`Temukan les privat bahasa asing terbaik dengan berbagai pilihan program fleksibel, termasuk les privat online dan offline. Dukungan belajar terbaik untuk Bahasa Inggris dan bahasa asing lainnya di ${nameCountry} bersama Les Private Matrix.
  `}
        />
        <meta
          property="og:url"
          content="https://apps.bimbelmatrix.com/les-privat-bahasa-asing"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://apps.bimbelmatrix.com/images/promo/september-back-to-school-min.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card Meta Tags */}
        <meta
          name="twitter:card"
          content="https://apps.bimbelmatrix.com/images/promo/september-back-to-school-min.png"
        />
        <meta
          name="twitter:title"
          content={`Bimbel Les Privat Bahasa Asing • Kursus Bahasa Inggris dan Asing di ${nameCountry} - Les
  Privat Matrix`}
        />
        <meta
          name="twitter:description"
          content={`Temukan les privat bahasa asing terbaik dengan berbagai pilihan program fleksibel, termasuk les privat online dan offline. Dukungan belajar terbaik untuk Bahasa Inggris dan bahasa asing lainnya di ${nameCountry} bersama Les Private Matrix.
  `}
        />
        <meta
          name="twitter:image"
          content="https://apps.bimbelmatrix.com/images/promo/september-back-to-school-min.png"
        />
        <meta name="twitter:site" content="@matrix_tutoring" />
      </Helmet>

      <div className="container-all">
        <HeroLesPrivate
          heading={heroData?.heading}
          desc={heroData?.description}
          nameCountry={nameCountry}
          images={heroData?.image}
        />
        <EmbrelaSlider />
        {/* <Slidertop /> */}
        <TableOfContents title="Table of Contents" items={items} />
        <LesPrivateBahasaHeader />

        <section id="pengajar-les-private">
          <PengajarLestPrivate />
        </section>
        <section id="fitur-program-les-private">
          {" "}
          content=
          {`Bimbel Les Privat Bahasa Asing • Kursus Bahasa Inggris dan Asing di ${nameCountry} - Les
  Privat Matrix`}
          <FiturProgram />
        </section>
        <PromoFree />
        <section id="pilihan-program-les-private">
          <PilihanProgram />
        </section>
        <section id="matrix-juga-melayani-les-privat-online">
          <OnlineLesOptions />
        </section>
        <section id="program-les-private-edumatrix">
          <ProgramLesPrivat />
        </section>
        <MasterCarousel />
        <ProgramBelajarLesPrivat />
        <MatrixFeatures />
        <section id="asal-sekolah-siswa-edumatrix-private">
          <AsalSekolahSiswaEdumatrix />
          <AsalSekolahSiswaMarque />
        </section>

        <Keunggulan />
        <ListKelurahanLesPrivate
          program={"les-privat-bahasa-asing"}
          title={"Les Privat Bahasa Asing"}
        />
        <AccordionFAQ />
        <PromoHomepage />
      </div>

      {/* <Floatingcta /> */}
      {/* <Bottombar /> */}
    </React.Fragment>
  );
};

export default LesPrivateBahasaKecamatan;
