import React from "react";
import "./HeroHome.css";

const HeroHome = () => {
  const phone = "6285747281466"; // Nomor telepon
  const baseUrl = `https://api.whatsapp.com/send?phone=${phone}&text=Halo%20Kak%20Linda%20https://apps.bimbelmatrix.com/,%20saya%20ingin%20tanya%20program%20belajar%20untuk:%0A`;
  const message = `
Kelas : 
Mapel : 
Kurikulum : 
Wilayah : 
`.trim();

  const finalUrl = `${baseUrl}${encodeURIComponent(message)}`;
  return (
    <div className="container-halaman-hero-home">
      <div className="content-hero-home">
        <div className="isi-content-hero-home">
          <h1 className="title-halaman-hero-home">
            Les Privat{" "}
            <span className="highlight-yellow">Terbaik #1 di Indonesia</span> -
            TK, SD, SMP, SMA, AKM, UTBK, SNBT, SIMAK UI, Mahasiswa, Bahasa,
            Mengaji, Komputer, TOEFL.
          </h1>
          <div>
            <p className="child-paragraf-hero-home">
              Bimbel Les Privat untuk TK, SD, SMP, SMA, UN/AKM, OSN, CPNS, LPDP,
              PPDS, SIMAK UI, UTBK SNBT, CBT UGM, UMPTN.
            </p>
            <p className="child-paragraf-hero-home">
              Dapatkan layanan Les Privat kapan pun dan dimana pun dengan lebih
              dari 5.000 Master Teacher Matrix yang siap memberikan pelayanan
              terbaik.
            </p>
          </div>
          {/* Added a second button */}
          <button
            className="btn-daftar-sekarang"
            onClick={() => window.open(finalUrl, "_blank")}>
            Daftar Sekarang
          </button>
          <button className="btn-learn-more">Pelajari Lebih Lanjut</button>
        </div>
        <img
          className="rumah-adat-hero-home"
          src={"/images/heroo.png"}
          alt="les privat Online & Guru ke Rumah (TK, SD, SMP, SMA, AKM, Mahasiswa, Bahasa Asing, Simak UI, SBMPTN) - Matrix Tutoring"
          width="1880"
          height="1075"
        />
      </div>
    </div>
  );
};

export default HeroHome;
